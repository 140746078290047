import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarIncidencia } from '../../../actions/colaboradores';

const EliminarIncidencia = ({ incidencia, reestablecerIncidencia }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar incidencia */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarIncidencia({
            id: incidencia?.id_colaborador, 
            id_incidencia: incidencia?._id
        }));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerIncidencia(null);
            setEliminarModal(false);
        }
    }

    /* Funciones generales */
    const convertPeriodosDias = (periodos) => {
        let dias = 0;
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos?.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());
                dias += Math.floor(diferencia / cantidad_dia) + 1;
            }
        }

        return dias;
    }

    useEffect(() => {
        if(incidencia != null){
            setEliminarModal(true);
        }
    }, [incidencia]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar incidencia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar la incidencia de {convertPeriodosDias(incidencia?.periodos)} {convertPeriodosDias(incidencia?.periodos) != 1 ? 'dias' : 'dia'}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarIncidencia;