import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { statusRemoto } from '../../../actions/colaboradores';

const StatusRemoto = ({ remoto, reestablecerRemoto }) => {

    const dispatch =  useDispatch();

    const [remotoData, setRemotoData] = useState({
        id: null, 
        id_remoto: null, 
        status: null
    });

    const [statusModal, setStatusModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Status remoto */
    const handleProcesar = async (status) => {
        await setStatusProcesar(true);
        await dispatch(statusRemoto({
            ...remotoData, 
            status
        }));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerRemoto(null);
            setStatusModal(false);
        }
    }

    /* Funciones generales */
    const convertPeriodosDias = (periodos) => {
        let dias = 0;
        let cantidad_dia = 1000 * 60 * 60 * 24;

        if(periodos?.length > 0){
            for (var x = 0; x < periodos.length; x++) {
                let diferencia = Math.floor(new Date(periodos[x].fin).getTime() - new Date(periodos[x].inicio).getTime());
                dias += Math.floor(diferencia / cantidad_dia) + 1;
            }
        }

        return dias;
    }

    useEffect(() => {
        if(remoto){
            setStatusModal(true);

            setRemotoData({
                ...remotoData, 
                id: remoto.id_colaborador,
                id_remoto: remoto._id,
                status: remoto.status
            });
        }
    }, [remoto]);

    return (
        <Modal show={statusModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Status de sesión de trabajo remoto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿A que status deseas actualizar la sesión de trabajo remoto de {convertPeriodosDias(remoto?.periodos)} {convertPeriodosDias(remoto?.periodos) != 1 ? 'dias' : 'dia'}?</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar && remotoData.status !== 2 ?
                        <button type="button" className="btn btn-danger mr-2" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rechazada</button>
                    :
                        remotoData.status !== 2 ?
                            <button type="button" className="btn btn-danger mr-2" onClick={() => handleProcesar(2)}><i className="fas fa-times fa-sm"></i> Rechazada</button>
                        :
                            null
                }

                {
                    statusProcesar && remotoData.status !== 0 ?
                        <button type="button" className="btn btn-warning mr-2" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Pendiente</button>
                    :
                        remotoData.status !== 0 ?
                            <button type="button" className="btn btn-warning mr-2" onClick={() => handleProcesar(0)}><i className="fas fa-pause fa-sm"></i> Pendiente</button>
                        :
                            null
                }

                {
                    statusProcesar && remotoData.status !== 1 ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Autorizada</button>
                    :
                        remotoData.status !== 1 ?
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(1)}><i className="fas fa-check fa-sm"></i> Autorizada</button>
                        :
                            null
                }
            </Modal.Footer>
        </Modal>
    )
}

export default StatusRemoto;