import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { crearColaborador } from '../../../actions/colaboradores';
//import { buscarPrestacionesConfiguracion } from '../../../actions/configuracion';
import { buscarEmpresas } from '../../../actions/empresas';
import { buscarColaboradoresEmpresa } from '../../../actions/colaboradores';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevoColaborador = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Colaboradores',
            path: '/colaboradores'
        },
        {
            activo: true,
            nombre: 'Nuevo colaborador',
            path: '/colaboradores/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado: listado_prestaciones } = useSelector(state => state.configuracion.prestaciones);
    const { listado: listado_empresas } = useSelector(state => state.empresas);
    const { listado: listado_colaboradores } = useSelector(state => state.colaboradores);

    const [colaboradorData, setColaboradorData] = useState({
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png', 
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        email_personal: null,
        telefono_personal: null,
        telefono_casa: null,
        direccion: null,
        curp: null, 
        rfc: null, 
        banco: null, 
        numero_cuenta: null, 
        clabe_interbancaria: null, 
        email: null,
        password: null,
        passwordConf: null, 
        pasatiempos: null, 

        tipo_usuario: null, 
        tipo_contrato: null, 
        puesto: null,
        empresa: null, 
        jefe_directo: null, 
        email_laboral: null,
        telefono_laboral: null,
        sueldo_mensual: null, 
        tipo_sueldo: null, 
        dias_vacaciones: null, 
        liga_calendario: null, 
        prestaciones: [], 

        tipo_sangre: null,
        seguro_externo: false, 
        compania_seguro: null, 
        poliza_seguro: null, 
        casado: false, 
        nombre_conyugue: null, 
        nacimiento_conyugue: null, 
        hijos: [], 
        contactos: [], 
        anotaciones: []
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined
    });
    
    const [tipoUsuarioData, setTipoUsuarioData] = useState(null);
    const tiposUsuarioFilter = [
        { value: 0, label: 'Administrador' }, 
        { value: 2, label: 'Colaborador' }
    ];

    const [tipoContratoData, setTipoContratoData] = useState(null);
    const tiposContratoFilter = [
        { value: 0, label: 'Medio tiempo' }, 
        { value: 1, label: 'Tiempo completo' }, 
        { value: 2, label: 'Freelance' }, 
        { value: 3, label: 'Remoto' }
    ];

    const [empresaData, setEmpresaData] = useState(null);
    const [empresasFilter, setEmpresasFilter] = useState([]);

    const [jefeDirectoData, setJefeDirectoData] = useState(null);
    const [jefesDirectosFilter, setJefesDirectosFilter] = useState([]);

    const [tipoSueldoData, setTipoSueldoData] = useState(null);
    const tiposSueldoFilter = [
        { value: 0, label: 'Neto' }, 
        { value: 1, label: 'Bruto' }
    ];

    const [prestacionesData, setPrestacionesData] = useState([]);
    const [prestacionesFilter, setPrestacionesFilter] = useState([]);

    const [tipoSangreData, setTipoSangreData] = useState(null);
    const tiposSangresFilter = [
        { value: 0, label: 'A+' },
        { value: 1, label: 'A-' },
        { value: 2, label: 'B+' },
        { value: 3, label: 'B-' },
        { value: 4, label: 'AB+' },
        { value: 5, label: 'AB-' },
        { value: 6, label: 'O+' },
        { value: 7, label: 'O-' }
    ];

    const [tipoSeguroData, setTipoSeguroData] = useState(null);
    const tiposSegurosFilter = [
        { value: false, label: 'No' },
        { value: true, label: 'Si' }
    ];

    const [tipoCasadoData, setTipoCasadoData] = useState(null);
    const tiposCasadosFilter = [
        { value: false, label: 'No' },
        { value: true, label: 'Si' }
    ];

    let img = createRef();

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nuevo colaborador */
    const handleChangeImg = () => {
        setProfilePicture({
            ...profilePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setColaboradorData({
            ...colaboradorData,
            [target.name]: target.value
        });
    }

    const handleNumberChange = (name, value) => {
        setColaboradorData({
            ...colaboradorData,
            [name]: value || ''
        });
    }

    const handleDatePicker = (name, value) => {
        setColaboradorData({
            ...colaboradorData,
            [name]: value
        });
    }

    const handleSelectTipoUsuario = (option) => {
        setTipoUsuarioData(option);

        setEmpresaData(null);

        setColaboradorData({
            ...colaboradorData,
            tipo_usuario: option != null ? option.value : null, 
            empresa: null
        });
    }

    const handleSelectTipoContrato = (option) => {
        setTipoContratoData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_contrato: option != null ? option.value : null
        });
    }

    const handleSelectEmpresa = (option) => {
        setEmpresaData(option);

        setJefeDirectoData(null);

        setColaboradorData({
            ...colaboradorData,
            empresa: option != null ? option.value : null, 
            jefe_directo: null
        });
    }

    const handleSelectJefeDirecto = (option) => {
        setJefeDirectoData(option);

        setColaboradorData({
            ...colaboradorData,
            jefe_directo: option != null ? option.value : null
        });
    }

    const handleSelectTipoSueldo = (option) => {
        setTipoSueldoData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_sueldo: option != null ? option.value : null
        });
    }

    const handleSelectPrestaciones = (option) => {
        setPrestacionesData(option);

        let prestaciones = [];

        for (var i = 0; i < option?.length; i++) {
            prestaciones.push(option[i].value);
        }

        setColaboradorData({
            ...colaboradorData,
            prestaciones
        });
    }

    const handleSelectTipoSangre = (option) => {
        setTipoSangreData(option);

        setColaboradorData({
            ...colaboradorData,
            tipo_sangre: option != null ? option.value : null
        });
    }

    const handleSelectTipoSeguro = (option) => {
        setTipoSeguroData(option);

        setColaboradorData({
            ...colaboradorData,
            seguro_externo: option != null ? option.value : false, 
            compania_seguro: option != null ? (option.value ? colaboradorData.compania_seguro : null) : null, 
            poliza_seguro: option != null ? (option.value ? colaboradorData.poliza_seguro : null) : null
        });
    }

    const handleSelectTipoCasado = (option) => {
        setTipoCasadoData(option);

        setColaboradorData({
            ...colaboradorData,
            casado: option != null ? option.value : false, 
            nombre_conyugue: option != null ? (option.value ? colaboradorData.nombre_conyugue : null) : null, 
            nacimiento_conyugue: option != null ? (option.value ? colaboradorData.nacimiento_conyugue : null) : null
        });
    }

    const handleGuardarColaborador = async () => {
        if(colaboradorData.nombre && colaboradorData.apellido_paterno && colaboradorData.tipo_usuario != null && colaboradorData.tipo_usuario != undefined){
            if(colaboradorData.email){
                if(colaboradorData.password !== colaboradorData.passwordConf){
                    await dispatch(setAlert('Las contraseñas no coinciden', 'danger'));
                    return;
                }
            }

            let formData = new FormData();

            formData.append('nombre', colaboradorData.nombre);
            formData.append('apellido_paterno', colaboradorData.apellido_paterno);
            formData.append('apellido_materno', colaboradorData.apellido_materno);
            formData.append('nacimiento', colaboradorData.nacimiento);
            formData.append('email_personal', colaboradorData.email_personal);
            formData.append('telefono_personal', colaboradorData.telefono_personal);
            formData.append('telefono_casa', colaboradorData.telefono_casa);
            formData.append('direccion', colaboradorData.direccion);
            formData.append('curp', colaboradorData.curp);
            formData.append('rfc', colaboradorData.rfc);
            formData.append('banco', colaboradorData.banco);
            formData.append('numero_cuenta', colaboradorData.numero_cuenta);
            formData.append('clabe_interbancaria', colaboradorData.clabe_interbancaria);
            formData.append('email', colaboradorData.email);
            formData.append('password', colaboradorData.password);
            formData.append('passwordConf', colaboradorData.passwordConf); 
            formData.append('pasatiempos', colaboradorData.pasatiempos); 

            formData.append('tipo_usuario', colaboradorData.tipo_usuario); 
            formData.append('tipo_contrato', colaboradorData.tipo_contrato); 
            formData.append('puesto', colaboradorData.puesto);
            formData.append('empresa', colaboradorData.empresa); 
            formData.append('jefe_directo', colaboradorData.jefe_directo); 
            formData.append('email_laboral', colaboradorData.email_laboral);
            formData.append('telefono_laboral', colaboradorData.telefono_laboral);
            formData.append('sueldo_mensual', colaboradorData.sueldo_mensual); 
            formData.append('tipo_sueldo', colaboradorData.tipo_sueldo); 
            formData.append('dias_vacaciones', colaboradorData.dias_vacaciones); 
            formData.append('liga_calendario', colaboradorData.liga_calendario);
            formData.append('prestaciones', JSON.stringify(colaboradorData.prestaciones));

            formData.append('tipo_sangre', colaboradorData.tipo_sangre);
            formData.append('compania_seguro', colaboradorData.compania_seguro); 
            formData.append('poliza_seguro', colaboradorData.poliza_seguro); 
            formData.append('nombre_conyugue', colaboradorData.nombre_conyugue); 
            formData.append('nacimiento_conyugue', colaboradorData.nacimiento_conyugue); 
            formData.append('hijos', JSON.stringify(colaboradorData.hijos));
            formData.append('contactos', JSON.stringify(colaboradorData.contactos));
            formData.append('anotaciones', JSON.stringify(colaboradorData.anotaciones));
            formData.append('perfil', profilePicture.img);

            await dispatch(crearColaborador(formData));
            await navigate("/colaboradores");
        }else{
            await dispatch(setAlert('El nombre, apellido paterno y el tipo de cuenta son requeridos', 'danger'));
        }
    }

    /* Hijos */
    const handleAgregarHijo = () => {
        let hijos = colaboradorData.hijos;

        hijos.push({
            nombre: null,
            nacimiento: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            hijos
        });
    }

    const handleHijoChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let hijos = colaboradorData.hijos;

        hijos[posicion].nombre = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            hijos
        });
    }

    const handleDatePickerHijo = (name, value) => {
        let nombre_separado = name.split('_');

        let posicion = nombre_separado[1];
        
        let hijos = colaboradorData.hijos;

        hijos[posicion].nacimiento = value;

        setColaboradorData({
            ...colaboradorData,
            hijos
        });
    }

    const handleFilasHijosCampos = (hijos) => {

        let hijos_campos = [];

        if(hijos){
            for (let x = 0; x < hijos?.length; x++) {
                hijos_campos.push(
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id={'nombreHijo_' + x} name={'nombreHijo_' + x} value={hijos[x].nombre || ''} onChange={e => handleHijoChange(e)}/>
                                <label htmlFor={'nombreHijo_' + x}>Hijo {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <DatePicker
                                    id={'nacimientoHijo_' + x}
                                    name={'nacimientoHijo_' + x}
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ hijos[x].nacimiento != null ? new Date(hijos[x].nacimiento) : '' }
                                    onChange={ date => handleDatePickerHijo('nacimientoHijo_' + x, date) }
                                />
                                <label className="label-datepicker" htmlFor={'nacimientoHijo_' + x}>Nacimiento</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarHijo_' + x} onClick={e => handleEliminarHijo(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return hijos_campos;
    }

    const handleEliminarHijo = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let hijos = colaboradorData.hijos;

        hijos.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            hijos
        });
    }

    /* Contactos de emergencia */
    const handleAgregarContacto = () => {
        let contactos = colaboradorData.contactos;

        contactos.push({
            nombre: null,
            parentesco: null,
            telefono: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            contactos
        });
    }

    const handleContactoChange = ({ target }) => {
        let nombre_separado = target.name.split('Contacto_');

        let posicion = nombre_separado[1];
        let name = nombre_separado[0];
        
        let contactos = colaboradorData.contactos;

        contactos[posicion][name] = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            contactos
        });
    }

    const handleFilasContactosCampos = (contactos) => {

        let contactos_campos = [];

        if(contactos){
            for (let x = 0; x < contactos?.length; x++) {
                contactos_campos.push(
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id={'nombreContacto_' + x} name={'nombreContacto_' + x} value={contactos[x].nombre || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'nombreContacto_' + x}>Contacto {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Parentesco" id={'parentescoContacto_' + x} name={'parentescoContacto_' + x} value={contactos[x].parentesco || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'parentescoContacto_' + x}>Parentesco {x + 1}</label>
                            </div>
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Teléfono" id={'telefonoContacto_' + x} name={'telefonoContacto_' + x} value={contactos[x].telefono || ''} onChange={e => handleContactoChange(e)}/>
                                <label htmlFor={'telefonoContacto_' + x}>Teléfono {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarContacto_' + x} onClick={e => handleEliminarContacto(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return contactos_campos;
    }

    const handleEliminarContacto = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let contactos = colaboradorData.contactos;

        contactos.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            contactos
        });
    }

    /* Anotaciones */
    const handleAgregarAnotacion = () => {
        let anotaciones = colaboradorData.anotaciones;

        anotaciones.push({
            contenido: null
        });

        setColaboradorData({ 
            ...colaboradorData, 
            anotaciones
        });
    }

    const handleAnotacionChange = ({ target }) => {
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let anotaciones = colaboradorData.anotaciones;

        anotaciones[posicion].contenido = target.value || '';

        setColaboradorData({
            ...colaboradorData,
            anotaciones
        });
    }

    const handleFilasAnotacionesCampos = (anotaciones) => {

        let anotaciones_campos = [];

        if(anotaciones){
            for (let x = 0; x < anotaciones?.length; x++) {
                anotaciones_campos.push(
                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Contenido" id={'contenidoAnotacion_' + x} name={'contenidoAnotacion_' + x} value={anotaciones[x].contenido || ''} onChange={e => handleAnotacionChange(e)}/>
                                <label htmlFor={'contenidoAnotacion_' + x}>Anotación {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarAnotacion_' + x} onClick={e => handleEliminarAnotacion(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return anotaciones_campos;
    }

    const handleEliminarAnotacion = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let anotaciones = colaboradorData.anotaciones;

        anotaciones.splice(id_separado[1], 1);

        setColaboradorData({ 
            ...colaboradorData, 
            anotaciones
        });
    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        if(colaboradorData.password !== ''){
            let loginInputType = document.getElementById(origen).type;
            
            if(loginInputType === 'password')document.getElementById(origen).type = 'text';
            else document.getElementById(origen).type = 'password';
        }
    }

    useEffect(() => {
        //dispatch(buscarPrestacionesConfiguracion());
        dispatch(buscarEmpresas());
    }, []);

    useEffect(() => {
        dispatch(buscarColaboradoresEmpresa(colaboradorData.empresa));
    }, [colaboradorData.empresa]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_prestaciones.length; i++) {
            options.push({ value: listado_prestaciones[i]._id, label: listado_prestaciones[i].nombre });
        }

        setPrestacionesFilter(options);
    }, [listado_prestaciones]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_empresas.length; i++) {
            options.push({ value: listado_empresas[i]._id, label: listado_empresas[i].nombre });
        }

        setEmpresasFilter(options);
    }, [listado_empresas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_colaboradores.length; i++) {
            options.push({ value: listado_colaboradores[i]._id, label: [listado_colaboradores[i].nombre, listado_colaboradores[i].apellido_paterno, listado_colaboradores[i].apellido_materno].join(' ') });
        }

        setJefesDirectosFilter(options);
    }, [listado_colaboradores]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #85ac11;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #caec67;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Nuevo colaborador</h3>

                    <div className="row mb-2">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={profilePicture.img != null ? URL.createObjectURL(profilePicture.img) : colaboradorData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="accordion" id="accordionDetalles">
                                
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Información personal
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={colaboradorData.nombre} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="nombre">Nombre</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={colaboradorData.apellido_paterno} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="apellido_paterno">Apellido paterno</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={colaboradorData.apellido_materno} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="apellido_materno">Apellido materno</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo" id="email_personal" name="email_personal" value={colaboradorData.email_personal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email_personal">Correo personal</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="nacimiento"
                                                        name="nacimiento"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        selected={ colaboradorData.nacimiento != null ? new Date(colaboradorData.nacimiento) : '' }
                                                        onChange={ date => handleDatePicker('nacimiento', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="nacimiento">Nacimiento</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono_personal" name="telefono_personal" value={colaboradorData.telefono_personal} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_personal">Teléfono personal</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono" id="telefono_casa" name="telefono_casa" value={colaboradorData.telefono_casa} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_casa">Teléfono de casa</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Dirección" id="direccion" name="direccion" value={colaboradorData.direccion} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="direccion">Dirección</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="CURP" id="curp" name="curp" value={colaboradorData.curp} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="curp">CURP</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="RFC" id="rfc" name="rfc" value={colaboradorData.rfc} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="rfc">RFC</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Banco" id="banco" name="banco" value={colaboradorData.banco} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="banco">Banco</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="No. de cuenta" id="numero_cuenta" name="numero_cuenta" value={colaboradorData.numero_cuenta} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="numero_cuenta">No. de cuenta</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="CLABE" id="clabe_interbancaria" name="clabe_interbancaria" value={colaboradorData.clabe_interbancaria} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="clabe_interbancaria">CLABE</label>
                                                </div>
                                            </div>
                                        </div>                                     

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={colaboradorData.email} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email">Correo de acceso</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="password" className="form-control" placeholder="Contraseña" id="password" name="password" value={colaboradorData.password} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="password">Contraseña</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password')}><i className="fa-solid fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="password" className="form-control" placeholder="Confirmar Contraseña" id="passwordConf" name="passwordConf" value={colaboradorData.passwordConf} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="passwordConf">Confirmar contraseña</label>
                                                </div>
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('passwordConf')}><i className="fa-solid fa-eye"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <textarea className="form-control textareCampo" placeholder="Hobbies" id="pasatiempos" name="pasatiempos" value={colaboradorData.pasatiempos} onChange={e => handleInputChange(e)}></textarea>
                                                    <label htmlFor="pasatiempos">Hobbies</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    Información profesional
                                </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_usuario"
                                                    name="tipo_usuario"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Cuenta"
                                                    options={tiposUsuarioFilter}
                                                    value={tipoUsuarioData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoUsuario(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_contrato"
                                                    name="tipo_contrato"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Contrato"
                                                    options={tiposContratoFilter}
                                                    value={tipoContratoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoContrato(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Puesto" id="puesto" name="puesto" value={colaboradorData.puesto} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="puesto">Puesto</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="empresa"
                                                    name="empresa"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Empresa"
                                                    options={empresasFilter}
                                                    value={empresaData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectEmpresa(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="jefe_directo"
                                                    name="jefe_directo"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Jefe directo"
                                                    options={jefesDirectosFilter}
                                                    value={jefeDirectoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectJefeDirecto(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Correo empresarial" id="email_laboral" name="email_laboral" value={colaboradorData.email_laboral} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="email_laboral">Correo empresarial</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Teléfono empresarial" id="telefono_laboral" name="telefono_laboral" value={colaboradorData.telefono_laboral} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="telefono_laboral">Teléfono empresarial</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <CurrencyInput
                                                        name="sueldo_mensual"
                                                        className="form-control text-center"
                                                        value={colaboradorData.sueldo_mensual || ''}
                                                        decimalsLimit={2}
                                                        groupSeparator=","
                                                        decimalSeparator="."
                                                        onValueChange={(value, name) => handleNumberChange(name, value)}
                                                    />
                                                    <label htmlFor="email_personal">Sueldo mensual</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_sueldo"
                                                    name="tipo_sueldo"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Tipo de sueldo"
                                                    options={tiposSueldoFilter}
                                                    value={tipoSueldoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoSueldo(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="number" className="form-control" placeholder="Vacaciones" id="dias_vacaciones" name="dias_vacaciones" value={colaboradorData.dias_vacaciones} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="dias_vacaciones">Vacaciones</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Liga de calendario" id="liga_calendario" name="liga_calendario" value={colaboradorData.liga_calendario} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="liga_calendario">Liga de calendario</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group mb-2">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="prestaciones"
                                                    name="prestaciones"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Bonos y prestaciones"
                                                    isMulti={true}
                                                    options={prestacionesFilter}
                                                    value={prestacionesData}
                                                    onChange={(value) => handleSelectPrestaciones(value)}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    Información familiar
                                </button>
                                </h2>
                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDetalles">
                                    <div className="accordion-body row">
                                        <div className="col-md-12 text-end mb-3">
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarHijo()}><i className="fa-solid fa-plus"></i>  Hijo</button>
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarContacto()}><i className="fa-solid fa-plus"></i>  Contacto</button>
                                            <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarAnotacion()}><i className="fa-solid fa-plus"></i>  Anotación</button>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="tipo_sangre"
                                                    name="tipo_sangre"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="Tipo de sangre"
                                                    options={tiposSangresFilter}
                                                    value={tipoSangreData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoSangre(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="seguro_externo"
                                                    name="seguro_externo"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="¿Seguro médico?"
                                                    options={tiposSegurosFilter}
                                                    value={tipoSeguroData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoSeguro(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Compañia" id="compania_seguro" name="compania_seguro" disabled={!colaboradorData.seguro_externo} value={colaboradorData.compania_seguro} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="compania_seguro">Compañia</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Póliza" id="poliza_seguro" name="poliza_seguro" disabled={!colaboradorData.seguro_externo} value={colaboradorData.poliza_seguro} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="poliza_seguro">Póliza</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group h-100">
                                                <Select
                                                    id="casado"
                                                    name="casado"
                                                    className="select-group"
                                                    classNamePrefix="react-select"
                                                    placeholder="¿Estás casado?"
                                                    options={tiposCasadosFilter}
                                                    value={tipoCasadoData}
                                                    isClearable={true}
                                                    onChange={(value) => handleSelectTipoCasado(value)}
                                                    menuPortalTarget={document.body}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    styles={{ ...styles_select }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" placeholder="Cónyugue" id="nombre_conyugue" name="nombre_conyugue" disabled={!colaboradorData.casado} value={colaboradorData.nombre_conyugue} onChange={e => handleInputChange(e)}/>
                                                    <label htmlFor="nombre_conyugue">Cónyugue</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2 form-group mb-3">
                                            <div className="input-group">
                                                <div className="form-floating">
                                                    <DatePicker
                                                        id="nacimiento_conyugue"
                                                        name="nacimiento_conyugue"
                                                        className="form-control text-center input-datepícker"
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        disabled={!colaboradorData.casado}
                                                        selected={ colaboradorData.nacimiento_conyugue != null ? new Date(colaboradorData.nacimiento_conyugue) : '' }
                                                        onChange={ date => handleDatePicker('nacimiento_conyugue', date) }
                                                    />
                                                    <label className="label-datepicker" htmlFor="nacimiento_conyugue">Nacimiento</label>
                                                </div>
                                            </div>
                                        </div>

                                        { handleFilasHijosCampos(colaboradorData.hijos) }
                                        { handleFilasContactosCampos(colaboradorData.contactos) }
                                        { handleFilasAnotacionesCampos(colaboradorData.anotaciones) }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarColaborador}>Guardar</button>
                            <Link to='/colaboradores'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoColaborador;