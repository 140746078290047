import { AUTH, PERFIL } from '../actions/types';

import setAuthToken from '../utils/setAuthToken';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    usuario: {}
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case AUTH.INICIAR:
            localStorage.setItem('token', payload.token); 
            setAuthToken(localStorage.token);

            return {
                ...state, 
                ...payload,
                isAuthenticated: true
            };

        case AUTH.ERROR:
        case AUTH.SESION_ERROR: 
        case AUTH.CERRAR: 
            localStorage.removeItem('token');
            
            return {
                ...state,
                token: null,
                isAuthenticated: false
            };

        case AUTH.SESION:
            return {
                ...state,
                isAuthenticated: true,
                usuario: payload
            };

        case PERFIL.EDITAR:
            return {
                ...state,
                usuario: payload
            };

        default:
            return state;
    }
}