import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

import { logout } from '../../actions/auth';

const Navbar = () => {

    const dispatch = useDispatch();

    const { permisos } = useSelector(state => state.auth.usuario);

    const handleLogout = () => {
        dispatch(logout());
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-primary">
            <div className="container-fluid">
                <div className="w-100">
                    <Link className='navbar-brand' to='/dashboard'>
                        <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo-dark.png'} alt="logo" height="35" style={{ marginRight: '0.5rem', verticalAlign: 'baseline' }} />
                    </Link>
                    <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse d-lg-flex justify-content-lg-center px-3" id="navbarNavDropdown">
                    <ul className="navbar-nav text-right">
                        <li className="nav-item">
                            <NavLink className={'nav-link'} to='/dashboard'>Inicio</NavLink>
                        </li>
                        <li className="nav-item dropdown mr-3">
                            <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Colaboradores
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                {
                                    permisos?.colaboradores?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/colaboradores/colaboradores'>Colaboradores</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.tipos_colaboradores?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/colaboradores/tipos'>Tipos</NavLink></li>
                                    :
                                        null
                                }
                            </ul>
                        </li>
                        <li className="nav-item dropdown mr-3">
                            <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Ingresos
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                {
                                    permisos?.punto_de_venta?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/ingresos/punto'>Punto de venta</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.cotizador?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/ingresos/cotizador'>Cotizador</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.clientes?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/ingresos/clientes'>Clientes</NavLink></li>
                                    :
                                        null
                                }
                            </ul>
                        </li>
                        <li className="nav-item dropdown mr-3">
                            <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Egresos
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                {
                                    permisos?.ordenes?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/egresos/ordenes'>Órdenes</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.gastos?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/egresos/gastos'>Gastos</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.proveedores?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/egresos/proveedores'>Proveedores</NavLink></li>
                                    :
                                        null
                                }
                            </ul>
                        </li>
                        {
                            permisos?.inventario?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/inventario'>Inventario</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.calendario?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/calendario'>Calendario</NavLink></li>
                            :
                                null
                        }
                        {
                            permisos?.reportes?.ver ?
                                <li className="nav-item"><NavLink className={'nav-link'} to='/reportes'>Reportes</NavLink></li>
                            :
                                null
                        }
                        <li className="nav-item dropdown mr-3">
                            <a className={'nav-link dropdown-toggle'} href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Configuración
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                                {
                                    permisos?.productos?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/productos'>Productos</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.servicios?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/servicios'>Servicios</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.unidades?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/unidades'>Unidades</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.categorias?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/categorias'>Categorias</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.ubicaciones?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/configuracion/ubicaciones'>Ubicaciones</NavLink></li>
                                    :
                                        null
                                }
                                {
                                    permisos?.perfil?.ver ?
                                        <li><NavLink className={'dropdown-item'} to='/perfil'>Perfil</NavLink></li>
                                    :
                                        null
                                }
                                <li><a className="dropdown-item" onClick={handleLogout} href="/">Cerrar sesión</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;